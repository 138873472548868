import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from './store';
import VueGtag from 'vue-gtag';
import VueI18n from 'vue-i18n';
import en from './locales/en.json';
// import zh from './locales/zh.json';



let gtag = "G-DVMJ66PVH9";

Vue.use(VueGtag, {
  // config: { id: process.env.NODE_ENV === 'production' ? 'G-DVMJ66PVH9' : null },
  config: { id: gtag },
}, router);


Vue.use(VueI18n);

Vue.use(ElementUI);

Vue.prototype.$API_ID = '100001';

Vue.config.productionTip = false;

// 配置 i18n
const i18n = new VueI18n({
  locale: 'en', 
  fallbackLocale: 'en', 
  messages: {
    en, 
  },
});

// const i18n = new VueI18n({
//   locale: 'zh', 
//   fallbackLocale: 'zh', 
//   messages: {
//     zh,
//   },
// });

const app = new Vue({
  store, 
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");


window.$gtag = app.$gtag;



