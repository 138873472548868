import Vue from "vue";
import VueRouter from "vue-router";
// import ChatDetailView from "@/views/ChatDetailView.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MainPage",
    component: () => import("@/MainPage.vue"), 
    props: true, 
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
