import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {}, 
    hasLogin: false, 
    needRegister: false, 
    uuid: [],
    chain: '',
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      state.hasLogin = true;
      if (userInfo.username.length > 30 && userInfo.username.indexOf('@') === -1 && userInfo.vip > 0) {
        state.needRegister = true;
      } else {
        state.needRegister = false;
      }

      // console.log("setUserInfo", state.uuid);
      if (!state.uuid.includes(userInfo.userId)) {
        state.uuid.push(userInfo.userId);
      }
      

      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      localStorage.setItem('uuid', JSON.stringify(state.uuid));
    },

    setChain(state, chain) {
      state.chain = chain;
      localStorage.setItem('chain', chain);
    },

    clearUserInfo(state) {
      state.userInfo = {};
      state.hasLogin = false;
      state.needRegister = false;
    },

    loadUuid(state, uuid) {
      if (!Array.isArray(uuid)) {
        uuid = [uuid];  
      }
      state.uuid = uuid;
      // console.log("loadUuid", uuid);
    },

    loadChain(state, chain) {
      state.chain = chain;
      // console.log("loadChain", chain);
    },

  },
  actions: {

    loadCachedUserInfo({ commit }) {
      const uuidCache = localStorage.getItem('uuid');
      if (uuidCache) {
        commit('loadUuid', JSON.parse(uuidCache));
      }

      const cache = localStorage.getItem('userInfo');
      if (cache) {
        const cachedInfo = JSON.parse(cache);
        if (cachedInfo && cachedInfo.token && cachedInfo.expireTime && cachedInfo.expireTime > Date.now()) {
          commit('setUserInfo', cachedInfo);
        } else {
          commit('clearUserInfo');
        }
      } else {
        commit('clearUserInfo');
      }

      const chainCache = localStorage.getItem('chain');
      if (chainCache) {
        commit('loadChain', JSON.parse(chainCache));
      }
      
    },

    saveUserInfo({ commit }, userInfo) {
      commit('setUserInfo', userInfo);
    },

    saveChain({ commit }, chain) {
      commit('setChain', chain);
    },

    logout({ commit }) {
      localStorage.removeItem('userInfo');
      commit('clearUserInfo');
    },

    getUserToken({ state }) {
      if (state.hasLogin && state.userInfo.token) {
        return state.userInfo.token;
      }
      throw new Error("You need login");
    },

    getUserUuid({ state }) {
      return state.uuid;
    },

    getChain({ state }) {
      return state.chain;
    },
  },
});
