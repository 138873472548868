<template>
  <div class="page">
    <router-view/>
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  created() {
    // 加载缓存的用户信息
    this.$store.dispatch('loadCachedUserInfo');
  },
};
</script>

<style lang="scss" scoped>
  .page {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: black;
  }
</style>
